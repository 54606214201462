.App {
  text-align: center;
  min-width: 375px;
  font-family: 'Oswald', sans-serif;
}

a{
  color: #bf9b30;
}

button {
  background-color:#dab637;
  box-shadow: none;
  border: none;
  padding: 1rem 0.5rem;
  font-size:1.2rem;
  font-family: 'Yellowtail', cursive;
  color: #282c34;
}
/* Header */
.App-header {
  background-color: #282c34;
  position:relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;

}



.header-bg{
  position: relative;
  opacity: 0.3;
}

.launch{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:80vw;
}

.logo{
  margin-bottom:2rem;
}
/* Jumbotron */
.jumbotron {
  height:100vh;
  margin: 0 auto;
  background-color: #f2f2f2;
  color:#bf9b30;

}

.jumbotron li {
  list-style-image:none;
}
  


.display-3{
  font-size:2.5rem;
  color:	#a67c00;
  font-family: 'Yellowtail', cursive;
}

.carousel-item img {
  width: 100%;
  height:auto;
}

/* Main Section */
.yachts{
  display:flex;
  flex-direction: column;
  color: #bf9b30;
}

.card {
  border: none;
}

.card-title{
  color: #a67c00;
  font-family: 'Yellowtail', cursive;
  font-size: 1.5rem;
}



li {
  list-style-image:url(./anchor.svg);
  padding-left:0.5rem;
  text-align: left;
}
/* Form */
.hidden{
  display:none;
}

.booking{
  width: 80%;
  margin:1rem auto;

}

.booking h2{
  font-family: 'Yellowtail', cursive;
  color: #a67c00;
  
}


form {
  display:flex;
  flex-direction: column;
  align-items: center;
  color: #bf9b30;
  width:100%;
  margin: 1rem auto;
}

label, input, textarea {
  width:80%;
  margin: 0.2rem auto;
}

.contact-button{
  width: 100%;
  margin-top:1rem;
}
/* Footer */
footer {
  display:flex;
  justify-content: space-evenly;
  height:2rem;
  margin:2rem auto 0;
  color: #bf9b30;
}
footer img {
  width:12%;
  height:auto;
  padding-right:0.5rem;
}

@media only screen and (min-width: 768px){
  .jumbotron{

    height:auto;

  }

  .carousel {
    width:45%;
    height:auto;
    margin:auto;
  }
  .card {
    width:40%;
  }

  .yachts{
    display:flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }
}

@media only screen and (min-width: 1024px) {
  

  .carousel {
    width:33%;
  }
 
  .card{
    width:33%;

  }

  .card-text ul{
    height: 28rem;
  
  }
}